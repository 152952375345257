
    import { Component, Vue, Mixins } from "vue-property-decorator";
    import BeforeRouteEnterMixin from "@/mixins/beforeRouteEnterMixin";
    import RoutesEnum from "@/router/routesEnum";
    import { InvoiceCreateModel, InvoicePrintURLModel, InvoicePrintURLQuery } from "@/apiManager/invoice/invoiceData";

    @Component
    export default class Booking extends Mixins(BeforeRouteEnterMixin)
    {
        get getInvoice() : InvoiceCreateModel
        {
            return this.$tStore.state.reservationState.invoice;
        }

        async beforeMount()
        {
            if (this.getInvoice.invoice.length === 0)
            {
                this.$router.push({ name: RoutesEnum.Package });
            }
        }

        async onClickOpenPDF()
        {
            let query: InvoicePrintURLQuery = new InvoicePrintURLQuery(this.getInvoice.invoice, this.getInvoice.file);

            let printModel: InvoicePrintURLModel = await this.$apiManager.invoice.getPrintURLAsync(query);

            window.open(printModel.url);
        }

        onClickHome()
        {
            this.$router.push({ name: RoutesEnum.Package });
        }
    }
